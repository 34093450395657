@import "../../App.scss";

.clients-container {
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 20px;
  gap: 40px;
  flex-direction: column;
}

.group-image {
  display: block;
  width: 100%;
}

.individual-images {
  display: none;
}

.clients-text {
  max-width: 400px;
  text-align: right;
  font-size: 18px;
  font-weight: 200;
  color: $primary-color;
}

.clients-text {
  margin: 0;
  line-height: 1.6;

  .clients-sub-title {
    margin-bottom: 40px;
    color: $primary-color;
    font-size: 28px;
    font-weight: 700;
    font-style: italic;
  }

  .title-highlighted {
    font-size: 40px;
    color: $forth-color;
    font-weight: 700;
    text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  }
}



@media (min-width: 768px) {
  .clients-container {
    flex-direction: row;
  }

  .group-image {
    display: block;
    width: 100%;
    max-width: 600px;
  }

  .individual-images {
    display: none;
  }
}

@media (max-width: 767px) {
  .group-image {
    display: none;
  }

  .individual-images {
    display: flex;
    flex-direction: column;
    gap: 20px;
    width: 100%;
  }

  .individual-images img {
    width: 100%;
    max-width: 300px;
    margin: 0 auto;
  }

  .clients-text {
    font-size: 16px;

    .clients-sub-title {
      font-size: 20px;
    }

    .title-highlighted {
      font-size: 24px;
    }
  }

}