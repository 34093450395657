@import "../../App.scss";

.contact-form-container {
  display: flex;
  justify-content: center;
  align-items: center;
  background: linear-gradient(135deg, #0CB8DACC, #066274CC);
  padding: 20px;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.1);
}

.contact-form-image {
  width: 50%;
  max-width: 600px;
  object-fit: cover;
}

.contact-form-content {
  width: 100%;
  max-width: 400px; 
  padding: 20px;
  color: $secondary-color;
  text-align: left;
  margin: 0 0 0 20px;
}

.contact-form-content h2 {
  font-size: 36px;
  font-weight: 700;
  margin-bottom: 10px;
  color: #FFFFFF; 
}

.contact-form-content p {
  margin-bottom: 20px;
  font-size: 16px;
  font-weight: 400;
  color: #FFFFFF; 
}

.contact-form-content form {
  display: flex;
  flex-direction: column;
}

.contact-form-content form input,
.contact-form-content form select,
.contact-form-content form textarea {
  font-size: 18px;
  margin-bottom: 20px;
  padding: 15px;
  border: none;
  border-radius: 8px;
  background-color: rgba(255, 255, 255, 0.8);
  color: #066274;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
}

.contact-form-content form input:focus,
.contact-form-content form select:focus,
.contact-form-content form textarea:focus {
  outline: none;
  background-color: rgba(255, 255, 255, 1);
}

.contact-form-content form textarea {
  height: 100px;
}

.contact-form-content form button {
  font-size: 24px; 
  font-weight: 700;
  padding: 12px;
  background-color: #FFFFFF;
  color: $tertiary-color;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0px 4px 10px rgba(0, 0, 0, 0.2);
  transition: background-color 0.3s ease;
  max-width: 244px;
  max-height: 48px;
  align-self: center; 
}

.contact-form-content form button:hover {
  background-color: $tertiary-color;
  color: #FFFFFF;
}

@media (max-width: 768px) {
  .contact-form-container {
    flex-direction: column;
    align-items: center;
  }

  .contact-form-image {
    width: 100%;
    margin-bottom: 20px;
  }

  .contact-form-content {
    width: 100%;
    max-width: 90%;
  }

  .contact-form-content form input,
  .contact-form-content form select,
  .contact-form-content form textarea {
    font-size: 16px;
  }
  .contact-form-content form button {
    font-size: 20px;
  }
}
