@import url('https://fonts.googleapis.com/css2?family=Open+Sans:ital,wght@0,300..800;1,300..800&family=Reddit+Sans+Condensed:wght@200..900&display=swap');

$primary-color: #00214D;
$secondary-color: #FFFFFF;
$tertiary-color: #0C9FDA;
$forth-color: #066274;

$primary-font-family: "Open Sans", sans-serif;

html,body {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
  font-family: $primary-font-family;
}

body {
  overflow: -moz-scrollbars-none; /* Firefox */
  -ms-overflow-style: none;  /* Internet Explorer y Edge */
  scrollbar-width: none; /* Firefox */
}

body::-webkit-scrollbar {
  display: none; /* Safari y Chrome */
}