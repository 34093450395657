@import "../../App.scss";

.hero-section-container {
  align-items: start;
  display: flex;
  justify-content: center;
  overflow: hidden;
  position: relative;
  width: 100%;
}

.hero-section-container img {
  width: 100%;
  max-height: 820px;
  object-fit: cover;
  object-position: top;
}

.hero-text {
  position: absolute;
  bottom: 80px;
  left: 74px;
  color: $secondary-color;
  z-index: 10;
  font-weight: 700;
  font-size: 40px;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  max-width: 560px;

  opacity: 0;
  transform: translateY(20px);
  animation: slideIn 2s ease-out forwards;
}

@media (max-width: 767px) {
  .hero-text {
    font-size: 20px;
    bottom: 20px;
    left: 20px;
    max-width: 100%;
    text-align: center;
  }
}

@keyframes slideIn {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }

  100% {
    opacity: 1;
    transform: translateY(0);
  }
}
