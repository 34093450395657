@import "../../App.scss"; 

.navbar-container {
  align-items: center;
  display: flex;
  justify-content: space-around;
  padding: 16px 0px;
}

.company-portal-link {
  color: $primary-color;
  font-family: $primary-font-family;
  font-weight: 600;
  font-size: 20px;
  text-decoration: none;
}

.company-portal-link:hover {
  color: $tertiary-color;
}

@media (max-width: 767px) {
  .company-portal-link {
    font-size: 16px;
  }
}
