@import "../../App.scss";

.footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  padding: 20px;
  background-color: $primary-color;
  text-align: center;

  @media (min-width: 768px) {
    justify-content: space-around;
    align-items: flex-start;
  }
}

.footer-container img {
  max-width: 70%;
  height: auto;
  margin-bottom: 20px;
  margin-top: 20px;

  @media (min-width: 768px) {
    align-self: center;
  }
}
