@import "../../../App.scss";

.footer-info-item {
  flex: 1 1 300px;
  max-width: 300px;
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 10px;

  @media (min-width: 768px) {
    &:nth-child(2), &:nth-child(3) {
      align-self: flex-start;
    }

    &:nth-child(2) {
      align-self: center;
    }
  }
}

.footer-info-title {
  margin-bottom: 10px;
  font-size: 28px;
  font-weight: 700;
  text-transform: uppercase;
  color: $secondary-color;
  @media (max-width: 768px) {
    font-size: 24px;
  }
}

.info-item {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  margin-bottom: 5px;
}

.info-item-icon {
  margin-right: 10px;
  height: 24px;
  width: 24px;
}

.info-item-texts {
  margin: 8px 0px;
  text-align: left;
  font-size: 20px;
  font-weight: 400;
  color: $secondary-color;
  @media (max-width: 768px) {
    font-size: 16px;
  }
}

.info-item-text {
  margin-bottom: 2px;
  text-align: center;
}
