@import "../../App.scss";

.our-location {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  margin-top: 20px;
}

.our-location-title-container {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-bottom: 20px;
}

.out-location-title {
  font-size: 48px;
  font-weight: 700;
  color: $primary-color;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
  margin-right: 20px;
}

.our-location-icon {
  width: 120px;
  height: 120px;
}

.video-container {
  position: relative;
  width: 820px;
  height: 404px;
  overflow: hidden;
  margin-bottom: 32px;
}

.video-container iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

@media (max-width: 767px) {
  .out-location-title {
    font-size: 32px;
    margin-right: 10px;
  }

  .our-location-icon {
    width: 80px;
    height: 80px;
  }

  .video-container {
    width: 100%;
    height: auto;
    padding-bottom: 56.25%;
  }

  .video-container iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
