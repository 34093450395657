@import "../../App.scss";

.our-services-container {
  background-color: rgba(237, 249, 253, 1);
  padding: 20px;
  text-align: center;
}

.our-services-title {
  font-size: 48px;
  color: $primary-color;
  font-weight: 700;
  text-shadow: 0px 4px 4px rgba(0, 0, 0, 0.25);
}

.our-services-group {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(150px, 1fr));
  gap: 20px;
  justify-items: center;
  max-width: 1200px;
  margin: 0 auto;
}

@media (max-width: 767px) {
  .our-services-title {
    font-size: 28px;
  }

  .our-services-group {
    grid-template-columns: repeat(auto-fit, minmax(120px, 1fr));
    gap: 15px;
  }
}

@media (min-width: 800px) {
  .our-services-group {
    grid-template-columns: repeat(4, 1fr);
    gap: 20px; /* Reducir espacio entre columnas en pantallas grandes */
    justify-content: center;
  }
}
