.company-portal-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  .portal-buttons {
    display: flex;
    justify-content: space-evenly;
    margin: 20px 0px;
    width: 100%;

    button {
      padding: 10px 20px;
      font-size: 16px;
      cursor: pointer;
      background-color: #f0f0f0;
      border: 1px solid #ccc;
      border-radius: 5px;
      transition: background-color 0.3s ease;

      &:hover {
        background-color: #e0e0e0;
      }

      &.active {
        background-color: #007bff;
        color: white;
        border-color: #007bff;
      }
    }
  }

  .portal-name {
    margin-bottom: 20px;
    text-align: center;
    font-size: 16px;
    font-weight: bold;
    color: #333;
    max-width: 300px;
  }

  .iframe-container {
    width: 100%;
    max-width: 1200px;
    overflow: hidden;
    position: relative;
    border: 1px solid #ccc;
    border-radius: 5px;
    box-shadow: 0px 4px 8px rgba(0, 0, 0, 0.1);
  }
}