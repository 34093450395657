@import "../../App.scss";

.highlighted-services {
  margin-bottom: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.highlighted-services-card {
  background-color: $primary-color;
  padding: 80px;
  width: 824px;
  margin: 40px;
  border-radius: 6px;
  box-shadow: 0px 4px 4px rgba(0,0,0,0.25);
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(200px, 1fr));
  gap: 40px;
  justify-items: start; 
}

@media (min-width: 800px) {
  .highlighted-services-card {
    grid-template-columns: repeat(3, 1fr);
  }
}
